import type { ComponentType } from "react"
import { useState, useEffect, Children } from "react"
// @ts-ignore
import { useQueryData } from "framer"

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
}

// Shuffle the children of a stack or grid layout
export function Shuffle(Component): ComponentType {
    return (props) => {
        const { children, ...otherProps } = props
        const childrenArray = Children.toArray(children)
        const [shuffledChildren, setShuffledChildren] = useState([])

        useEffect(() => {
            setShuffledChildren(shuffleArray(childrenArray))
        }, [])

        return <Component {...otherProps}>{shuffledChildren}</Component>
    }
}

// Shuffle the children of a CMS Collection List
export function ShuffleCMSCollectionList(Component): ComponentType {
    return (props) => {
        const query = props.children?.props?.query

        const [shuffledData, setShuffledData] = useState(
            query?.from?.data || []
        )

        if (!query) {
            return <Component {...props} />
        }

        useEffect(() => {
            const data = query?.from?.data
            if (data) {
                const newData = []
                for (const i in data) {
                    newData[i] = data[i]
                }

                setShuffledData(shuffleArray(newData))
            }
        }, [])

        return (
            <Component {...props}>
                {props.children?.props?.children(
                    useQueryData({
                        ...query,
                        from: { ...query?.from, data: shuffledData },
                    })
                )}
            </Component>
        )
    }
}
